body {
  min-height: -webkit-fill-available;
  min-height: 100vh;
  background-color: #F9F9F9;
}

.ant-layout {
  background: none !important;
}

@font-face {
  font-family: 'GothamRounded-Medium';
  src: local('GothamRounded-Medium'), url(./fonts/GothamRounded-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'GothamRounded-Book';
  src: local('GothamRounded-Book'), url(./fonts/GothamRounded-Book.otf) format('opentype');
}