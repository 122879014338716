.Guide {
  text-align: center;
  > .ant-modal-content {
    background: rgba(255, 255, 255, 0.9);
    padding-bottom: 20px;
    > .ant-modal-header {
      background-color: #22292E;
      > .ant-modal-title {
        color: #fff;
      }
    }
    > .ant-modal-close {
      color: #fff;
    }
  }
  .ant-divider {
    border-top: 1px solid rgba(13, 44, 98, 0.25);
  }
  p {
    text-align: left;
  }
  &__checklist {
    text-align: left;
    list-style: none;
    padding-left: 0;
    > li {
      position: relative;
      padding-left: 26px;
      margin-bottom: 6px;
      &:before {
        content: '✓'; 
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        left: 0;
        top: -2px;
        color: #4CAF50;
      }
    }
  }
  &__steps {
    text-align: left;
    padding-left: 22px;
    > li {
      margin-bottom: 6px;
    }
  }
  &__cover {
    display: flex;
    justify-content: center;
    padding: 10px 6px 0;
  }
  .ant-card-body {
    padding: 12px 4px 12px 8px;
  }
  &__meta {
    .ant-card-meta-description {
      line-height: 20px;
    }
  }
  .Icon__android {
    max-width: 44%;
    padding: 2px;
    @media (max-width: 576px) {
      max-width: 70px;
    }
  }
  .Icon__apple {
    max-width: 53%;
    padding: 2px;
    @media (max-width: 576px) {
      max-width: 70px;
    }
  }
  .Icon__chrome {
    max-width: 53%;
    @media (max-width: 576px) {
      max-width: 150px;
    }
  }
}