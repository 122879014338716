.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  background-color: #22292E;
  .Icon__centricLogo {
    max-width: 150px;
    .screen-xs & {
      max-width: 120px;
    }
  }
}

.Header__dropdown {
  width: 180px;
  justify-content: flex-end;
  padding-right: 0;
  &__button {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    &:active,
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.8);
    } 
    .ant-badge-status-dot {
      width: 9px;
      height: 9px;
    }
    .ant-badge-status-text {
      margin-left: 8px;
    }
  }
}

.ConnectWalletButton {
  cursor: pointer;
  background: #0082ff;
  color: #fff;
  border: none;
  font-size: 16px;
  line-height: 22px;
  border-radius: 5px;
  padding: 4px 10px;
  height: 37px;
  &:hover {
    opacity: 0.85;
  }
}