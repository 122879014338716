.LoadingFrame {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoadingFrame__spinner {
  display: inline-block;
  animation: loading 1.5s linear infinite;
  @keyframes loading {
    0% { 
      transform: rotate(0) scale(1);
    }
    50% { 
      transform: rotate(180deg) scale(1.2);
    }
    100% { 
      transform: rotate(360deg) scale(1);
    }
  }
}