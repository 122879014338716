.SwapButton {
  background-color: #EEF2F2;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  > img {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    width: 18px;
  }
  &.rotate {
    > img {
      -webkit-transform:rotate(180deg);
      -moz-transform:rotate(180deg); 
      -o-transform:rotate(180deg);
      transform:rotate(180deg);
    }
  }
  &:hover {
    outline: none;
    border: none;
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  &:active, 
  &:focus {
    border: none;
    outline: none;
  }
}