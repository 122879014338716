.CurrencySelect {
  background-color: #EEF2F2;
  height: 60px;
  width: 136px !important;
  border-radius: 0 5px 5px 0;  
  @media only screen and (max-width: 900px) {
    height: 46px !important;
    width: 110px !important;
  }
  > div.ant-select-selector {
    height: 60px !important;
    @media only screen and (max-width: 900px) {
      height: 46px !important;
    }
  }
  &.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 18px;
  }
  .ant-select-arrow {
    font-size: 18px;
    width: 18px;
    height: 18px;
    top: 50%;
    color: #B8BCBC;
    .anticon:not(.anticon-down) {
      pointer-events: none !important;
    }
  }
  &:hover {
    background-color: rgba($color: #EEF2F2, $alpha: 0.65);
    .ant-select-arrow {
      color: rgba($color: #242C30, $alpha: 0.85);
    }
  }
}

.CurrencySelect__options {
  background-color: rgba($color: #fff, $alpha: 1);
  .CurrencySelect__option__title {
    margin-top: 0;
  }
  .ant-select-item-option-active {
    background-color: #EEF2F2;
  }
  .ant-select-item-option-selected {
    background-color: #EEF2F2;
  }
}

.CurrencySelect__option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  @media only screen and (max-width: 900px) {
    height: 46px !important;
  }
  &__title {
    font-size: 12px;
    line-height: 16px;
    font-family: 'GothamRounded-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #929393;
    margin-top: -4px;
    @media only screen and (max-width: 900px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    font-size: 26px;
    line-height: 30px;
    color: #242C30;
    font-weight: 400;
    @media only screen and (max-width: 900px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .Icon {
    &__tokenIcon {
      width: 24px;
      margin-right: 6px;
      @media only screen and (max-width: 900px) {
        width: 18px;
        margin-right: 4px;
      }
    }
  }
}