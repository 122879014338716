.Connect {
  text-align: center;
  > .ant-modal-content {
    background: rgba(255, 255, 255, 0.9);
    padding-bottom: 20px;
    > .ant-modal-header {
      background-color: #22292E;
      > .ant-modal-title {
        color: #fff;
      }
    }
    > .ant-modal-close {
      color: #fff;
    }
  }
  .ant-card-body {
    padding: 12px 4px 12px 8px;
  }
  &__meta {
    .ant-card-meta-description {
      line-height: 20px;
    }
  }
  &__link {
    display: block;
    margin-top: 1.5rem;
  }
}