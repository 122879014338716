.SwapForm__amountSelect {
  margin-bottom: 8px;
}

.SwapForm__item {
  display: flex;
}

.SwapForm__info {
  display: flex;
  justify-content: space-between;
  color: #A4A4A4;
  margin-bottom: 8px;
  @media only screen and (max-width: 900px) {
    margin-bottom: 4px;
  }  
}

.SwapForm__input {
  height: 60px;
  display: flex;
  margin-bottom: 0;
  padding: 4px 8px;
  background-color: #fff;
  border: 1px solid #EEF2F2;
  border-radius: 5px 0 0 5px;
  @media only screen and (max-width: 900px) {
    height: 48px;
    padding: 2px 6px;
    width: 100%;
  }
  &--disabled {
    background-color: #F9F9F9;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }
  .ant-form-item-label {
    padding-bottom: 0;
    line-height: 1em;
    width: 100%;
    .screen-xs & {
      flex: none;
    }
    @media only screen and (max-width: 900px) {
      flex: none;
    }
    > label {
      font-size: 12px;
      line-height: 16px;
      font-family: 'GothamRounded-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #929393;
      @media only screen and (max-width: 900px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  .ant-form-item-control {
    .screen-xs & {
      flex: none;
    }
    @media only screen and (max-width: 900px) {
      flex: none;
    }
  }
  .ant-form-item-control-input  {
    .screen-xs & {
      min-height: auto;
    }
    @media only screen and (max-width: 900px) {
      min-height: auto;
    }
  }
  .ant-form-item-control-input-content {
    background: none;
    border: none;
    color: #fff;
    height: 30px;
    @media only screen and (max-width: 900px) {
      height: 20px;
    }
    &:hover,
    &:focus {
     border: none; 
     background: none;
    }
    > input.ant-input {
      background: none;
      color: #242C30;
      padding: 0;
      font-size: 28px;
      line-height: 30px;
      border: none;
      @media only screen and (max-width: 900px) {
        font-size: 20px;
        line-height: 22px;
      }
      &::placeholder {
        color: #242C30;
      }
      &:focus {
        box-shadow: none;
        &::placeholder {
          color: rgba($color: #fff, $alpha: 0.45);
        }
      }
    }
    &.ant-input-affix-wrapper-lg { 
      > input.ant-input {
        font-size: 26px;
        line-height: 30px;
        @media only screen and (max-width: 900px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}