.Confirm {
  display: flex;
  justify-content: center;
  &__inner {
    width: 100%;
  }
  &__stats {
    display: flex;
    justify-content: space-between;
    color: #A4A4A4;
    padding: 8px 2px;
    &__link {
      text-decoration: underline;
      color: #A4A4A4;
      &:hover {
        color: #242C30;
      }
    }
  }
}

.Confirm__button {
  color: #fff;
  width: 100%;
  border: none;
  font-size: 22px;
  line-height: 26px;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  .screen-xs & {
    font-size: 20px;
    line-height: 24px;
    padding: 8px;
  }
  &:hover:not(:disabled) {
    background-color: #001529;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:active,
  &:focus {
    outline: none;
  }
  > img {
    height: 22px;
  }
  &--idle {
    background: #00E799;
  }
  &--success {
    background:#00C500;
  }
  &--failed {
    background:rgba(208, 2, 27, 0.4);
  }
  &--loading {
    background:#123F85;
  }
  &--resultSuccess {
    background:#00C500;
  }
  &--resultFailed {
    background:#c62828;
  }
}