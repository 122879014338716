.AmountSelect {
  &.ant-radio-group {
    border: 1px solid #EEF2F2;
    background: #EEF2F2;
    border-radius: 5px;
    padding: 1px;
    > label {
      color: #A2A3A3;
      background: #EEF2F2;
      border: none;
      font-size: 13px;
      line-height: 22px;
      height: auto;
      padding: 2px 14px;
      .screen-xs & {
        font-size: 12px;
        line-height: 20px;
        height: auto;
        padding: 2px 10px;
      }
      &.ant-radio-button-wrapper {
        &::before {
          content: none;
        }
        &:hover {
          background: #fff;
        }
      }
      &.ant-radio-button-wrapper-checked {
        background: #fff;
        border-radius: 5px;
        box-shadow: none;
      }
    }
  }
  
}